    import AvatarLogo from '../assets/images/done/TeamNurse/avatar.jpg';
export const contractData = [
    {
        id: 1,
        country: 'Croatia',
        sector: 'Construction / Blacksmithing / Joinery / Painting / Electrical mechanic / Air conditioning / Driver license D / Butcher / hotel service',
        language: 'Language not required',
        visaDuration: '5 months',
        contractDuration: '1-year employment contract',
        contractPrice: '60,000 DH',
        additionalInfo: 'The contract includes free accommodation and medical treatment.',
        international: 'International',
        latitude: 45.1,
        longitude: 15.2,
      },
      {
        id: 2,
        country: 'Germany',
        sector: 'Health and Nursing / Hotels / Programming',
        language: 'Required language B1',
        visaDuration: '8 months',
        contractDuration: '1-year employment contract',
        contractPrice: '80,000 DH',
        additionalInfo: 'The contract includes free accommodation, medical treatment, and language courses.',
        international: 'International',
        latitude: 51.1657,
        longitude: 10.4515,
      },
      {
        id: 3,
        country: 'Belgium',
        sector: 'Health and Nursing / Hotels / Programming',
        language: 'Language B1 is necessary',
        visaDuration: '8 months',
        contractDuration: '1-year employment contract',
        contractPrice: '60,000 DH',
        additionalInfo: 'The contract includes free accommodation, transportation allowances, and medical insurance.',
        international: 'International',
        latitude: 50.8503,
        longitude: 4.3517,
      },
      {
        id: 4,
        country: 'Italy',
        sector: 'Health and Nursing / Hotels / Agriculture',
        language: 'Language B1 is necessary',
        visaDuration: '8 months',
        contractDuration: '1-year employment contract',
        contractPrice: '60,000 DH',
        additionalInfo: 'The contract includes free accommodation, meals, and access to medical care.',
        international: 'International',
        latitude: 41.8719,
        longitude: 12.5674,
      },
      {
        id: 5,
        country: 'Spain',
        sector: 'Health and Nursing / Hotels / Agriculture',
        language: 'Language B1 is necessary',
        visaDuration: '8 months',
        contractDuration: '1-year employment contract',
        contractPrice: '60,000 DH',
        additionalInfo: 'The contract includes free accommodation, flight tickets, and comprehensive health coverage.',
        international: 'International',
        latitude: 40.4637,
        longitude: -3.7492,
      },
      {
        id: 6,
        country: 'Canada',
        sector: 'IT / Engineering / Skilled Trades',
        language: 'Language B2 required',
        visaDuration: '12 months',
        contractDuration: '2-year employment contract',
        contractPrice: '120,000 DH',
        additionalInfo: 'The contract includes free accommodation, family relocation assistance, and health benefits.',
        international: 'International',
        latitude: 56.1304,
        longitude: -106.3468,
      },
      {
        id: 7,
        country: 'Australia',
        sector: 'Healthcare / Hospitality / Skilled Trades',
        language: 'English required',
        visaDuration: '6 months',
        contractDuration: '1.5-year employment contract',
        contractPrice: '100,000 DH',
        additionalInfo: 'The contract includes free accommodation, visa sponsorship, and medical insurance.',
        international: 'International',
        latitude: -25.2744,
        longitude: 133.7751,
      },
      {
        id: 8,
        country: 'United Arab Emirates',
        sector: 'Construction / Security / Hospitality',
        language: 'English required',
        visaDuration: '4 months',
        contractDuration: '1-year employment contract',
        contractPrice: '50,000 DH',
        additionalInfo: 'The contract includes free accommodation, flight allowances, and transport.',
        international: 'International',
        latitude: 23.4241,
        longitude: 53.8478,
      },
      {
        id: 9,
        country: 'Japan',
        sector: 'IT / Technology / Teaching',
        language: 'Japanese N4 required',
        visaDuration: '12 months',
        contractDuration: '2-year employment contract',
        contractPrice: '150,000 DH',
        additionalInfo: 'The contract includes free accommodation, language training, and health insurance.',
        international: 'International',
        latitude: 36.2048,
        longitude: 138.2529,
      },
      {
        id: 10,
        country: 'Sweden',
        sector: 'Healthcare / Engineering / IT',
        language: 'Language B2 required',
        visaDuration: '10 months',
        contractDuration: '1.5-year employment contract',
        contractPrice: '110,000 DH',
        additionalInfo: 'The contract includes free accommodation, relocation support, and access to public services.',
        international: 'International',
        latitude: 60.1282,
        longitude: 18.6435,
      },
    
    
  ];
  
  export const testimonials = [
    {
        name: 'Sarah L.',
        quote: 'The care I received was outstanding. The nurses were professional, kind, and truly cared about my well-being.',
        image: AvatarLogo,// Replace with actual image paths
      },
      {
        name: 'Michael P.',
        quote: 'Thanks to the wonderful team, my recovery was smooth and comfortable. I couldn’t have asked for better care.',
        image: AvatarLogo, // Replace with actual image paths
      },
      {
        name: 'Jessica W.',
        quote: 'Our family is grateful for the compassionate care provided to our elderly parents. Highly recommended!',
        image: AvatarLogo, // Replace with actual image paths
      },
  ];
  